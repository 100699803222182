@font-face {
    font-family: "Eurostile";
    /*src: url(../fonts/EurostileLTStd.eot); !* IE *!*/
    src: local("Eurostile"), url(../fonts/EurostileLTStd.otf) format("opentype"); /* non-IE */
}

body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

h1,h2,h3,h4,h5,h6 {
    font-family: "Eurostile";
    font-weight: bolder;
}

.footer {
    display: block;
    background-image: url("../images/logo-gpelite-2-wit.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 200px;
    height: 70px;
}

.well {
    border-radius: 0;
    border: 1px solid #cecece;
}

.navbar-brand {
    font-family: "Eurostile";
    font-weight: bold;
}

.btn {
    border: 1px solid darkgrey;
    border-radius: 0;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

.history-breadcrumb {
    margin-top: 60px;
    font-size: smaller;
    color: #1a6ecc;
}
.well {
    margin-top: 65px;
}

/* ==========================================================================
navbar text
========================================================================== */
.navbar-default .navbar-brand {
    color: #444;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.error {
    color: white;
    background-color: red;
}

.hipster {
    display: inline-block;
    width: 700px;
    height: 120px;
    background: url("../images/logo-gpelite-2-wit.png") no-repeat center top;
    background-size: contain;
}

.pad {
    padding: 10px;
}
/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        /*background: url("../images/hipster2x.png") no-repeat center top;*/
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a {
    color: #1c0cf5;
}

a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
    margin: auto 10px auto 10px;
}
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

/*
    EMS-1316: The from and to date input are not at the same line anymore, so make the from and to labels equal width
    if both input-group-addon and input-group-addon-date are set, make the width 50px;
*/
.input-group-addon.input-group-addon-date {
    width: 50px;
}

.point:last-of-type {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}
/* end Password strength bar style */

.readonly {
    background-color: #eee;
    opacity: 1;
}

/*
    Alle nieuwe css styles hier toevoegen en omschrijven
*/
/* wordt gebruikt op main.html om de linker kolom waar logo in staat een zwarte achtergrond te geven */
.gpelite-main-logo {
    padding-top: 20px;
    /*background-color: #000000;*/
}


/*give the sort icons some space on their left*/
.glyphicon.glyphicon-sort-by-attributes,
.glyphicon.glyphicon-sort-by-attributes-alt,
.glyphicon.glyphicon-sort  {
    left:5px;
}

/*make clickable sortable table headers look like they are clickable*/
table th.sortable {
    cursor: pointer;
}

.row{
    margin-bottom: 10px
}


/* jhipster-needle-css-add-main JHipster will add new css style */
/* 3 styles that are solely used for animating the search button icon */
.glyphicon-refresh-animate {
    -animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

@media (min-width: 1600px) {
    .container {
        width: 80%;
    }
}

/*the following takes care of a dropdown in a table to be shown completely*/
.table-responsive {
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.available-instructor-mismatch {
    color: red;
}

/* normally the form-group margin is 15px, however this leads to much vertically consumed space which we try to minimize a bit */
div.form-group {
    margin: 5px;
}

/* for search by date we add a p.input-group into the form-group, which has a margin being not in line with other form-groups, here we neutralize it */
div.form-group p.input-group {
    margin: 0;
}

div.container h2:first-of-type {
    margin-top: 0;
    margin-bottom: 10px;
}

span.form-control-static.white-space-pre {
    white-space: pre-wrap;
}


.nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

/*Custom modal dialog*/
.modal-lg.wide {
    width: 80%;
}

.big-checkbox {
    width: 20px;
    height: 20px;
}

.vertical-center {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.form-control {
    transition: ease-in-out, background-color .35s ease-in-out;
}

.form-control:focus {
    background-color: #fffcef;
}

/*!*we limit the top and bottom margin of a row on a modal-body to 0 as these are purely meant for form purposes*!*/
.modal-body .row {
    margin-top: 0;
    margin-bottom: 0;
}

/*.modal-body label {*/
/*!* by fixing the height for labels on a modal-body that reside next to a form-control to the same height as the form-control *!*/
/*!* we control the layout in a nicer way, 34px seems sufficient *!*/
/*height: 34px;*/
/*}*/

/* give panel little bit more top margin */
.modal-body .panel {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* make panel heading big in height */
.modal-body .panel .panel-heading {
    height: 12px;
    padding-top: 2px;
    font-weight: bold;
    font-size: smaller;
}

.debug td {
    border: 1px dashed lightgray;
}

div.table-responsive > table.layout-container > tbody > tr > td {
    border-top: 0px;
}
div.table-responsive > table.layout-container > tbody > tr > td > div.panel {
    margin-bottom: 0px;
}
div.table-responsive > table.layout-container > tbody > tr > td > div.panel > div.panel-body {
    padding:10px;
}
div.table-responsive > table.layout-container > tbody > tr > td > div.panel > div.panel-body > div > table {
    margin-bottom: 0px;
}

.animate-show,
.animate-hide {
    -webkit-transition:all ease 0.5s;
    -moz-transition:all ease 0.5s;
    -ms-transition:all ease 0.5s;
    -o-transition:all ease 0.5s;
    transition:all ease 0.5s;
}

.animate-show.ng-hide-remove,
.animate-hide.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    display: block !important;
}

.animate-hide.ng-hide-add,
.animate-show.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    display: block !important;
}
.animate-show-tr,
.animate-hide-tr {
    -webkit-transition:all ease 0.5s;
    -moz-transition:all ease 0.5s;
    -ms-transition:all ease 0.5s;
    -o-transition:all ease 0.5s;
    transition:all ease 0.5s;
}

.animate-show-tr.ng-hide-remove,
.animate-hide-tr.ng-hide-add.ng-hide-add-active {
    opacity: 0;
    display: table-row !important;
}

.animate-hide-tr.ng-hide-add,
.animate-show-tr.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
    display: table-row !important;
}

/* style to support multiple spans on 1 line in TD, this one is designed for showing subscriptions with badges and inline labels in 1 line without breaks*/
.label-subscription {
    display: inline-block;
    background-color: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    margin-top: 5px;
}

.label-additional {
    font-size: 1em;
    font-weight: bold;
    /*background-color: inherit;*/
    /*color: inherit;*/
    /*border: 4px solid #5bc0de;*/
}

.label-license {
    font-size: 1em;
    font-weight: bold;
    border: 2px solid black;
    color: black;
}


hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #ccc;
}

/* avoid wrapping for 3 action buttons */
.actionButtons {
    min-width: 95px;
}


.scrolling table {
    table-layout: inherit;
    *margin-left: -100px;/*ie7*/
    font-size: 0.8em;
}
.scrolling thead tr th {
    border: 0;
    vertical-align: top;
}
.scrolling td, th {
    vertical-align: top;
    padding: 10px;
    min-width: 100px;
}
.scrolling tbody td:first-child, .scrolling thead th:first-child {
    position: absolute;
    *position: relative; /*ie7*/
    left: 0;
    width: 200px;
}
.scrolling tbody td:not(:first-child), .scrolling thead th:not(:first-child) {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}
.outer {
    position: relative;
}
.inner {
    overflow-x: auto;
    overflow-y: visible;
    margin-left: 200px;
}

.scrolling thead th:not(:first-child) {
    width: 110px;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
}
/* the correct sizing for the personname */
.scrolling td:first-child .sales-status-relation:first-child {
    width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* the correct sizing for the email and telephone numbers */
.scrolling td:first-child .sales-status-relation:not(:first-child) {
    width: 196px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.panel.sales-status {
    /*border: 2px solid lightgrey;*/
    border: 0;
    border-radius: 0;
    margin: 0;
    width: 106px;
    min-width: 106px;
    max-width: 106px;
}

div.panel.sales-status-cell:hover {
    cursor: pointer;
}
/* This is used to fix the layout in the modal of Sales dashboard, @see main.css#376 .modal-body label */
.modal-body #subscriptionDetails label {
    height: 27px;
}

/**
    EMS-882 FIX to prevent an initially hidden ui-select from having a ui-select-search input of just 10px width
    See line 845 in version 0.19.8, it sets the width to 10px.

    By hiding the search panel by default initially certain methods are not called from select.js controller, causing the correct width not to be calculated and set.

    See https://github.com/angular-ui/ui-select/issues/1617
 */
div.ui-select-container input.ui-select-search {
    width: 100% !important;
}

/* pimp general panel styling a bit */
.panel {
    -webkit-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.10);
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.10);
}
/*Capitalize the first letter of all form labels including panel headings*/
.mf-panel > div > .panel-heading, label:first-letter {
    text-transform: capitalize;
    font-weight: bold;
}

/* Additonal style for panel,panel-heading used in index */
.mf-panel {
    margin-top: 80px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.10);
}

.mf-panel > div > .panel-heading {
    display: flex;
    align-items: center;
    background-color: #fff;
}

.mf-panel > div > .panel-heading > .panel-title {
    margin-right: auto;
}
.mf-panel > div > .panel-heading > .panel-title > h3 {
    font-size: 24px;
}

.mf-panel > div > .panel-heading > .panel-title > .panel-sub-title {
    font-weight: normal;
    color: #bfbfbf;
}

.mf-panel > div > .panel-heading > .panel-buttons {
    margin-left: auto;
}

.mf-panel > div > .panel-heading > .panel-buttons > button {
    margin-left: 2px;
}

.actionButtons .btn {
    margin-left: 2px;
}

.price-equal {
	color: green;
}

.price-not-equal {
	color: orangered;
}

.btn-a {
	background-color: rgb(239, 239, 239);
	color: rgb(51, 51, 51);
}

.oneline {
  white-space: nowrap;
}
